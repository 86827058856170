import request from '../../../util/request';

export const getAllAdcListApi = () => request.get('/crm/pincode');

export const createNewAdcApi = data =>
  request.post('/crm/createAdcPincode', data);

export const updateAdcApi = data =>
  request.put(`/crm/updateAdcPincode/${data.serviceablePinId}`, data);

export const deleteAdcApi = serviceablePinId =>
  request.del(`/crm/deleteAdcPincode/${serviceablePinId}`);
