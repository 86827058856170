import request from '../../../util/request';

export const getAllPagesApi = () => request.get('/crm/page');

export const getPageByNameApi = ({ pageName }) =>
  request.get(`/crm/page/${pageName}`);

export const postPageApi = data => request.post('/crm/page', data);

export const putPageApi = data => request.put('/crm/page', data);

export const getCTByPageApi = ({ pageName }) =>
  request.get(`/crm/customTileByPagename/${pageName}`);

export const getPageSettingsApi = ({ name = 'homepage' }) =>
  request.get(`/crm/pageWithSections/${name}`);

export const updatePageSettingsApi = data =>
  request.put('/crm/pageWithSections', data);
