import { SET_PAGE_LOADING } from './page-settings.action';

const initialState = {
  pageSettings: null,
  loadingPage: false,
};

const pageSettingsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_PAGE_LOADING:
      return {
        ...state,
        loadingPage: data,
      };
    default:
      return state;
  }
};

export default pageSettingsReducer;
