import {
  SET_INIT_PROVISIONAL_DATA,
  SET_PROVISIONAL_LOADING,
} from './provisional-category.action';

const initialState = {
  loadingProvisional: false,
  masterData: null,
};

const provisionalCategoryReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INIT_PROVISIONAL_DATA:
      return {
        ...state,
        ...data,
        loadingProvisional: false,
      };
    case SET_PROVISIONAL_LOADING:
      return {
        ...state,
        loadingProvisional: data,
      };
    default:
      return state;
  }
};

export default provisionalCategoryReducer;
