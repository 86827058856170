const prefix = 'PROVISIONAL_CATEGORY/';
export const GET_INIT_PROVISIONAL_DATA = `${prefix}GET_INIT_PROVISIONAL_DATA`;
export const SET_INIT_PROVISIONAL_DATA = `${prefix}SET_INIT_PROVISIONAL_DATA`;
export const SET_PROVISIONAL_LOADING = `${prefix}SET_PROVISIONAL_LOADING`;

export const getInitProvisionalData = () => ({
  type: GET_INIT_PROVISIONAL_DATA,
});

export const setProvisionalLoading = () => ({ type: SET_PROVISIONAL_LOADING });
