import { combineReducers } from 'redux';
import initialReducer from './inital.reducer';
import loginReducer from './feature/login/login.reducer';
import productReducer from './feature/products/product.reducer';
import customerReducer from './feature/customers/customer.reducer';
import orderReducer from './feature/orders/order.reducer';
import customTileReducer from './feature/custom-tile/custom-tile.reducer';
import promoReducer from './feature/promo/promo.reducer';
import pageSettingsReducer from './feature/page-settings/page-settings.reducer';
import masterReducer from './feature/master-data/master.reducer';
import puReducer from './feature/pu/pu.reducer';
import blogsReducer from './feature/blogs/blogs.reducer';
import appUpdateReducer from './feature/app-update/app-update.reducer';
import hbPointReducer from './feature/hb-point/hbpoint.reducer';
import provisionalCategoryReducer from './feature/provisional-category/provisional-category.reducer';
import systemPropertyReducer from './feature/system-property/system-property.reducer';
import seoSettingReducer from './feature/seo-setting/seo-setting.reducer';
import adcPincodeReducer from './feature/adc-pincode/adcpincode.reducer';

const rootReducer = combineReducers({
  initialReducer,
  loginReducer,
  productReducer,
  customerReducer,
  orderReducer,
  customTileReducer,
  promoReducer,
  pageSettingsReducer,
  masterReducer,
  puReducer,
  blogsReducer,
  appUpdateReducer,
  hbPointReducer,
  provisionalCategoryReducer,
  systemPropertyReducer,
  seoSettingReducer,
  adcPincodeReducer,
});

export default rootReducer;
