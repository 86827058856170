import request from '../../../util/request';

export const getAllPrimePlans = () =>
  request.get('/crm/getAllPrimeMembershipList');

export const getPrimePlanById = id =>
  request.get(`/crm/getPrimeMembershipByMembershipId?hbMembershipId=${id}`);

export const postPrimePlan = data =>
  request.post('/crm/savePrimeMembership', data);

export const putPrimePlan = data =>
  request.put('/crm/editPrimeMembership', data);

export const getMembershipList = () =>
  request.get('/crm/getAllHbMemberShipCustomer');

export const getPrimeMembershipByCustomerIDApi = ({ customerId }) =>
  request.get(`/crm/getPrimeMembershipByCustomerID/${customerId}`);

export const postCancelPrimeMembershipApi = data =>
  request.post('/crm/cancelAndRefundPrimeMembership', data);

export const getPrimeMembers = () => request.get('/crm/membershipCustomer');

export const searchPrimeMembers = data =>
  request.post('/crm/searchHbMembershipCustomers', data);
