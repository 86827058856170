import request from '../../../util/request';

export const getInitCTDetailsApi = () =>
  request
    .get('/crm/initCTDetails')
    .catch(e => ({ action: 'success', data: { key: 12, val: 'sadsf ' } }));

export const getCTByIdApi = ctId => request.get('/crm/customTile/' + ctId);

export const getAllCTApi = () => request.get('/crm/customTile');

export const postCTApi = data => request.post('/crm/customTile', data);

export const putCTApi = data => request.put('/crm/customTile', data);
