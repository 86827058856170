import {
  SET_ORDERS,
  SET_ORDER_BY_NO,
  SET_LOADING_ORDER,
  SET_PUT_ORDER,
  SET_FAILED_ORDERS,
  MERGE_ORDERS,
  RESET_ORDER_DETAILS,
  SET_ACTIVE_TAB,
} from './order.action';
import { ORDER_TYPES } from '../../../util/ConstantsService';

const initialState = {
  orders: null,
  pendingOrders: null,
  todaysOrders: null,
  tomorrowsOrders: null,
  orderDetails: null,
  loadingOrders: false,
  failedOrders: null,
  membershipDetails: null,
};

const orderReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ORDERS:
      return {
        ...state,
        ...data,
        loadingOrders: false,
      };
    case MERGE_ORDERS:
      const { orderType, orders } = data;
      const returnState = {
        ...state,
        loadingOrders: false,
      };
      switch (orderType) {
        case ORDER_TYPES.PENDING_ORDERS:
          returnState.pendingOrders = [...state.pendingOrders, ...orders];
          break;
        case ORDER_TYPES.TODAYS_ORDERS:
          returnState.todaysOrders = [...state.todaysOrders, ...orders];
          break;
        case ORDER_TYPES.TOMORROWS_ORDERS:
          returnState.tomorrowsOrders = [...state.tomorrowsOrders, ...orders];
          break;
        default:
          returnState.orders = [...state.orders, ...orders];
      }
      // if (orderType === PENDING_ORDERS_ORDERTYPE) {
      //   returnState.pendingOrders = [...state.pendingOrders, ...orders];
      // } else {
      //   returnState.orders = [...state.orders, ...orders];
      // }
      return returnState;
    case SET_ORDER_BY_NO:
      return {
        ...state,
        ...data,
        loadingOrders: false,
      };
    case SET_LOADING_ORDER:
      return {
        ...state,
        loadingOrders: data,
      };
    case SET_PUT_ORDER:
      return {
        ...state,
        orderDetails: data,
        loadingOrders: false,
      };
    case SET_FAILED_ORDERS:
      return {
        ...state,
        failedOrders: data,
        loadingOrders: false,
      };
    case RESET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: null,
        loadingOrders: false,
      };
    default:
      return state;
  }
};

export default orderReducer;
