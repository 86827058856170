import { createPromiseAction } from '@adobe/redux-saga-promise';

const prefix = 'CUSTOM_TILE/';
export const GET_INIT_CT_DETAILS = createPromiseAction(
  `${prefix}GET_INIT_CT_DETAILS`
);
export const SET_INIT_CT_DETAILS = `${prefix}SET_INIT_CT_DETAILS`;
export const UPSERT_CT = `${prefix}UPSERT_CT`;
export const GET_ALL_CT = `${prefix}GET_ALL_CT`;
export const SET_ALL_CT = `${prefix}SET_ALL_CT`;
export const SET_LOADING_CT = `${prefix}SET_LOADING_CT`;

// export const getInitCTDetails = data => ({ type: GET_INIT_CT_DETAILS, data });
export const getInitCTDetails = GET_INIT_CT_DETAILS;

export const upsertCT = data => ({ type: UPSERT_CT, data });

export const getAllCT = () => ({ type: GET_ALL_CT });
