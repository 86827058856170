import { takeEvery } from 'redux-saga/effects';
import {
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  UPSERT_CUSTOMER,
  UPSERT_ADDRESS,
  ORDER_FOR_CUSTOMER,
  SEARCH_CUSTOMER,
} from './customer.action';
import {
  getCustomersSaga,
  getCustomerByIdSaga,
  upsertCustomerSaga,
  upsertAddressSaga,
  postOrderForCustomerSaga,
  searchCustomersSaga,
} from './customer.saga';
import { promisifySaga } from '../promisifySaga';

export const customerSaga = [
  takeEvery(GET_CUSTOMERS, getCustomersSaga),
  takeEvery(GET_CUSTOMER_BY_ID, getCustomerByIdSaga),
  takeEvery(UPSERT_CUSTOMER, upsertCustomerSaga),
  takeEvery(UPSERT_ADDRESS, upsertAddressSaga),
  takeEvery(ORDER_FOR_CUSTOMER, promisifySaga(postOrderForCustomerSaga)),
  takeEvery(SEARCH_CUSTOMER, searchCustomersSaga),
];
