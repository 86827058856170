import { createPromiseAction } from '@adobe/redux-saga-promise';

const prefix = 'ORDERS/';
export const GET_ORDERS = `${prefix}GET_ORDERS`;
export const SET_ORDERS = `${prefix}SET_ORDERS`;
export const MERGE_ORDERS = `${prefix}MERGE_ORDERS`;
export const SEARCH_ORDERS = `${prefix}SEARCH_ORDERS`;
export const GET_ORDER_BY_NO = `${prefix}GET_ORDER_BY_NO`;
export const SET_ORDER_BY_NO = `${prefix}SET_ORDER_BY_NO`;
export const PUT_ORDER = `${prefix}PUT_ORDER`;
export const SET_PUT_ORDER = `${prefix}SET_PUT_ORDER`;
export const SET_LOADING_ORDER = `${prefix}SET_LOADING_ORDER`;
export const CANCEL_SHIPMENT = createPromiseAction(`${prefix}CANCEL_SHIPMENT`);
export const GET_FAILED_ORDERS = `${prefix}GET_FAILED_ORDERS`;
export const SET_FAILED_ORDERS = `${prefix}SET_FAILED_ORDERS`;
export const UPDATE_FAILED_ORDERS = `${prefix}UPDATE_FAILED_ORDERS`;
export const RESET_ORDER_DETAILS = `${prefix}RESET_ORDER_DETAILS`;
export const GET_ORDER_PAYMENT_STATUS = createPromiseAction(
  `${prefix}GET_ORDER_PAYMENT_STATUS`
);

export const getOrders = data => ({ type: GET_ORDERS, data });

export const getOrderById = data => {
  return {
    type: GET_ORDER_BY_NO,
    data,
  };
};

export const putOrder = data => {
  return {
    type: PUT_ORDER,
    data,
  };
};

export const searchOrders = data => ({ type: SEARCH_ORDERS, data });

export const cancelShipment = CANCEL_SHIPMENT;

export const getFailedOrders = () => ({ type: GET_FAILED_ORDERS });

export const updateFailedOrders = data => ({
  type: UPDATE_FAILED_ORDERS,
  data,
});

export const resetOrderDetails = () => ({ type: RESET_ORDER_DETAILS });
// export const failedOrdersSelector = ({ orderReducer: { failedOrders } }) =>
//   failedOrders;

export const getOrderPaymentStatus = GET_ORDER_PAYMENT_STATUS;
