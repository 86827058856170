import { SET_MASTER_DATA } from './master.action';

const initialState = {
  city: null,
  adc: null,
  clientType: null,
  customerType: null,
  category: null,
  subCategory: null,
  productClass: null,
  product: null,
  promoType: null,
};

const masterReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_MASTER_DATA:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

export default masterReducer;
