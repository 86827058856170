import { SET_LOADING, SET_APP_UPDATE_DETAILS } from './app-update.action';

const initialState = {
  appUpdateDetails: [],
  loading: false,
};

const appUpdateReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_APP_UPDATE_DETAILS:
      return {
        ...state,
        appUpdateDetails: data,
        loading: false,
      };
    default:
      return state;
  }
};

export default appUpdateReducer;
