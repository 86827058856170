import { call, put, all } from 'redux-saga/effects';
import {
  SET_INIT_HBPOINT_DATA,
  SET_HBPOINT_LOADING,
  SET_ALL_CAMPAIGN_DATA,
  SET_ALL_CAMPAIGN_BY_ID,
} from './hbpoint.action';

import { getAllCampaignApi, getCampaignByCampaignIdAPI } from './hbpoint.api';

import {
  getCity,
  getAdc,
  getClientType,
  getCustomerType,
  getCategory,
  getProduct,
  getProductClass,
  getSubCategory,
  getPromoTypes,
  getCountry,
  getState,
  getPaymentMode,
  getCampaignCategory,
} from '../master-data/master.api';

export function* getHbPointDataSaga() {
  try {
    yield put({ type: SET_HBPOINT_LOADING, data: true });
    const promises = {
      city: call(getCity),
      adc: call(getAdc),
      clientType: call(getClientType),
      customerType: call(getCustomerType),
      category: call(getCategory),
      products: call(getProduct),
      productClass: call(getProductClass),
      subCategory: call(getSubCategory),
      promoType: call(getPromoTypes),
      country: call(getCountry),
      state: call(getState),
      paymentMode: call(getPaymentMode),
      campaignCategory: call(getCampaignCategory),
    };
    const masterData = yield all(promises);
    const respData = { masterData };
    yield put({ type: SET_INIT_HBPOINT_DATA, data: respData });
  } catch (e) {
    console.log('Err@ getInitHbPointDataSaga: ', e);
    yield put({ type: SET_HBPOINT_LOADING, data: false });
  }
}
export function* getAllCampaignSaga() {
  try {
    yield put({ type: SET_HBPOINT_LOADING, data: true });
    const { data } = yield call(getAllCampaignApi);
    yield put({ type: SET_ALL_CAMPAIGN_DATA, data });
  } catch (e) {
    console.log('Err@ getAllCampaignSaga: ', e);
    yield put({ type: SET_HBPOINT_LOADING, data: false });
  }
}

export function* getAllCampaignByIdSaga(id) {
  try {
    yield put({ type: SET_HBPOINT_LOADING, data: true });
    let { data } = yield call(getCampaignByCampaignIdAPI, id?.data);
    data = { ...data, treeData: JSON.parse(data?.treeData) || '' };
    yield put({ type: SET_ALL_CAMPAIGN_BY_ID, data });
  } catch (e) {
    console.log('Err@ getAllCampaignByIdSaga: ', e);
    yield put({ type: SET_HBPOINT_LOADING, data: false });
  }
}
