import { takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_CT,
  GET_INIT_CT_DETAILS,
  UPSERT_CT,
} from './custom-tile.action';
import {
  getInitCTDetailSaga,
  getAllCTSaga,
  upsertCTSaga,
} from './custom-tile.saga';
import { promisifySaga } from '../promisifySaga';

export const customTilesSaga = [
  takeEvery(GET_INIT_CT_DETAILS, promisifySaga(getInitCTDetailSaga)),
  takeEvery(UPSERT_CT, upsertCTSaga),
  takeEvery(GET_ALL_CT, getAllCTSaga),
];
