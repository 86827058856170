import Rollbar from 'rollbar';

let rollbar = null;

const initialize = () => {
  const HOSTs = ['crm.myhalalbox.in', 'halalbox.in'];
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    reportLevel: 'error',
    enabled: HOSTs.indexOf(window.location?.host) > -1,
  };
  rollbar = new Rollbar(rollbarConfig);
  return rollbar;
};

export default {
  initialize,
  getRollbarInstance: () => {
    if (rollbar) return rollbar;
    return initialize();
  },
};
