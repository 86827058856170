const prefix = 'PROMO/';
export const GET_INIT_PROMO_DATA = `${prefix}GET_INIT_PROMO_DATA`;
export const SET_INIT_PROMO_DATA = `${prefix}SET_INIT_PROMO_DATA`;
export const GET_ALL_PROMOS = `${prefix}GET_ALL_PROMOS`;
export const SET_ALL_PROMOS = `${prefix}SET_ALL_PROMOS`;
export const GET_PROMO_BY_ID = `${prefix}GET_PROMO_BY_ID`;
export const SET_PROMO_BY_ID = `${prefix}SET_PROMO_BY_ID`;
export const UPSERT_PROMO = `${prefix}UPSERT_PROMO`;
export const RESET_PROMO = `${prefix}RESET_PROMO`;
export const SET_PROMO_LOADING = `${prefix}SET_PROMO_LOADING`;
export const SEARCH_PROMO = `${prefix}SEARCH_PROMO`;
export const SET_PROMO_TYPE = `${prefix}SET_PROMO_TYPE`;
export const MERGE_PROMO = `${prefix}MERGE_PROMO`;

export const getInitPromoData = data => ({ type: GET_INIT_PROMO_DATA, data });

export const getAllPromos = data => ({ type: GET_ALL_PROMOS, data });

export const getPromoById = data => ({ type: GET_PROMO_BY_ID, data });

export const upsertPromo = data => ({ type: UPSERT_PROMO, data });

export const resetPromo = () => ({ type: RESET_PROMO });

export const setPromoLoading = () => ({ type: SET_PROMO_LOADING });

export const searchPromo = data => ({ type: SEARCH_PROMO, data });
