const prefix = 'HBPOINT/';
export const GET_INIT_HBPOINT_DATA = `${prefix}GET_INIT_HBPOINT_DATA`;
export const SET_INIT_HBPOINT_DATA = `${prefix}SET_INIT_HBPOINT_DATA`;
export const SET_HBPOINT_LOADING = `${prefix}SET_HBPOINT_LOADING`;
export const GET_ALL_CAMPAIGN_DATA = `${prefix}GET_ALL_CAMPAIGN_DATA`;
export const SET_ALL_CAMPAIGN_DATA = `${prefix}SET_ALL_CAMPAIGN_DATA`;
export const GET_ALL_CAMPAIGN_BY_ID = `${prefix}GET_ALL_CAMPAIGN_BY_ID`;
export const SET_ALL_CAMPAIGN_BY_ID = `${prefix}SET_ALL_CAMPAIGN_BY_ID`;

export const getInitHbPointData = () => ({
  type: GET_INIT_HBPOINT_DATA,
});

export const setHbPointLoading = () => ({ type: SET_HBPOINT_LOADING });

export const getAllCampaignData = () => ({
  type: GET_ALL_CAMPAIGN_DATA,
});

export const getAllCampaignById = data => ({
  type: GET_ALL_CAMPAIGN_BY_ID,
  data,
});
