import { call, put, delay, all } from 'redux-saga/effects';
import { notification } from 'antd';
import { SET_SYSTEM_PROPERTY, SET_LOADING } from './system-property.action';
import { getAllSystemPropertyApi } from './system-property.api';

export function* getAllSystemPropertySaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data } = yield call(getAllSystemPropertyApi);
    yield put({ type: SET_SYSTEM_PROPERTY, data });
  } catch (e) {
    console.log('Err@ getAllSystemPropertySaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error loading system properties' });
  }
}
