import { call } from 'redux-saga/effects';
// import {
//   SET_PAGE_LOADING,
//   SET_PAGE_SETTINGS,
// } from './page-settings.action';
import {
  getPageSettingsApi,
  getAllPagesApi,
  updatePageSettingsApi,
  getPageByNameApi,
  postPageApi,
  putPageApi,
  getCTByPageApi,
} from './page-settings.api';
// import {
//   getCity,
//   getAdc,
//   getClientType,
//   getCustomerType,
//   getCategory,
//   getProduct,
//   getProductClass,
//   getSubCategory,
//   getPromoTypes,
// } from './../../master.api';
import { notification } from 'antd';
import history from '../../history';

export function getHomePageSettingsSaga({ data }) {
  // const promises = {
  //   city: call(getCity),
  //   adc: call(getAdc),
  //   clientType: call(getClientType),
  //   customerType: call(getCustomerType),
  //   category: call(getCategory),
  //   products: call(getProduct),
  //   productClass: call(getProductClass),
  //   subCategory: call(getSubCategory),
  //   promoType: call(getPromoTypes),
  // };
  // if (data.promoId) {
  //   promises.promoDetails = call(getPromoByIdApi, data);
  // }

  // const masterData = yield all(promises);
  // const respData = { masterData };
  // if (data.promoId) {
  //   respData.promoDetails = masterData.promoDetails;
  //   delete respData.masterData.promoDetails;
  // }
  return getPageSettingsApi(data);
}

export function getAllPagesSaga() {
  return getAllPagesApi();
}

export function getPageByNameSaga({ data }) {
  return Promise.all([getPageByNameApi(data), getCTByPageApi(data)]);
}

export function postPageSaga({ data }) {
  return postPageApi(data);
}

export function putPageSaga({ data }) {
  return putPageApi(data);
}

export function* updatePageSettingsSaga({ data }) {
  try {
    yield call(updatePageSettingsApi, data);
    notification.success({ message: 'Update Home page settings' });
  } catch (e) {
    console.log('Err@ updatePageSettingsSaga: ', e);
  }
}

// export function* getAllPromosSaga() {
//   try {
//     yield put({ type: SET_PROMO_LOADING, data: true });
//     const { data } = yield call(getAllPromosApi);
//     yield put({ type: SET_ALL_PROMOS, data });
//   } catch (e) {
//     console.log('Err@ getAllPromosSaga: ', e);
//     notification.error({
//       message: 'Error getting promo list. Please try later.',
//     });
//     yield put({ type: SET_PROMO_LOADING, data: false });
//   }
// }
// export function* getPromoByIdSaga({ data }) {
//   try {
//     yield put({ type: SET_PROMO_LOADING, data: true });
//     const { data: promoDetails } = yield call(getPromoByIdApi, data);
//     if (!promoDetails.promotionId) throw new Error('No such promo found');
//     yield put({ type: SET_PROMO_BY_ID, data: promoDetails });
//   } catch (e) {
//     console.log('Err@ getPromoByIdSaga: ', e);
//     const errMsg =
//       e?.message || 'Error getting promo details. Please try later.';
//     notification.error({ message: errMsg });
//     yield put({ type: SET_PROMO_LOADING, data: false });
//   }
// }
