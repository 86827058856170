import {
  SET_INIT_GENERATE_QR_DATA,
  SET_SKU_FOR_SKID,
  SET_SKIDS,
  SET_LOADING,
  SET_PU_MASTER_DATA,
  SET_INVOICES,
  SET_INVOICE_BY_NO,
  SET_INVOICE_ITEM_DETAILS,
} from './pu.action';

const initialState = {
  loading: false,
  skus: null,
  // skus: [{"productImage":"https://ik.imagekit.io/iwcam3r8ka/prod/products/202007/ca8d76ac-338c-40ad-b471-dad5906b5df2.jpg","productId":35,"sku":"FIS-1011","productName":"White Pomfret (Whole)","description":"No Antibiotic No Preservative Pomfret is a treat for fish lovers. The White Pomfret is a diamond shaped fish. White Pomfrets are found in the Indian Ocean. Like any other fish, white pomfret has health benefits too. Pomfrets are a rich source of essential fatty acids (Omega-3) and minerals. Eating pomfret can improve your eyesight, hair and skin as it is high in calcium, vitamins A, D, B & B12. You can whip up a lot of yummy recipes like Pomfret Tandoor and pomfret curry with coconut. You can also enjoy pomfret curry with rice or chapatti. Order White Pomfret from Halalbox and get it delivered within 90 minutes","category":"Fish & Seafood","subCategory":"Fresh","productClass":"Sea Water","size":"6 Pieces","cut":"Medium","action":"Generate QR Code"}],
  skids: null,
  invoices: null,
  invoice: null,
  invoiceItemDetails: null,
  // invoiceItemDetails: {"puInvoiceItemId":18,"puInvoiceId":11,"sku":"SF-gxo8","quantity":1,"costPrice":850,"totalItemPrice":850,"sgstTax":5,"sgstTaxPrice":42.5,"cgstTax":5,"cgstTaxPrice":42.5,"productName":"Salmon Steak","scannedQuantity":0,"invoiceNumber":"PU01-MUM03-100005","adcCode":"MUM03"},
  // invoiceItemDetails: {"puInvoiceItemId":13,"puInvoiceId":10,"sku":"CHK-rl42","quantity":2,"costPrice":210,"totalItemPrice":420,"sgstTax":5,"sgstTaxPrice":21,"cgstTax":5,"cgstTaxPrice":21,"productName":"Chicken Drumsticks","scannedQuantity":0,"invoiceNumber":"PU01-MUM01-100004","adcCode":"MUM01"},
  // invoiceItemDetails: {"puInvoiceItemId":16,"puInvoiceId":10,"sku":"CHK-7y27","quantity":5,"costPrice":255,"totalItemPrice":1275,"sgstTax":5,"sgstTaxPrice":63.75,"cgstTax":5,"cgstTaxPrice":63.75,"productName":"Chicken Leg Boneless","scannedQuantity":0,"invoiceNumber":"PU01-MUM01-100004","adcCode":"MUM01"},
};

const puReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INIT_GENERATE_QR_DATA:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_SKU_FOR_SKID:
      return {
        ...state,
        skus: data,
        loading: false,
      };
    case SET_SKIDS:
      return {
        ...state,
        skids: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: data,
        loading: false,
      };
    case SET_INVOICE_BY_NO:
      return {
        ...state,
        invoice: data,
        loading: false,
      };
    case SET_PU_MASTER_DATA:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_INVOICE_ITEM_DETAILS:
      return {
        ...state,
        invoiceItemDetails: data,
      };
    default:
      return state;
  }
};

export default puReducer;
