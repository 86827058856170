import { createStore, applyMiddleware, compose } from 'redux';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root.reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState => {
  const sagaMiddleware = createSagaMiddleware();
  return {
    ...createStore(
      rootReducer,
      preloadedState,
      composeEnhancers(applyMiddleware(promiseMiddleware, sagaMiddleware))
    ),
    runSaga: sagaMiddleware.run,
  };
};

export default configureStore;
