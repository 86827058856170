import { call, put, all } from 'redux-saga/effects';
import { SET_INIT_ADC_DATA, SET_ADC_LOADING } from './adcpincode.action';

import { getCity, getAdc } from '../master-data/master.api';
import { getAllAdcListApi } from './adcpincode.api';

export function* getInitAdcPincodeDataSaga() {
  try {
    yield put({ type: SET_ADC_LOADING, data: true });
    let filterType = {};
    const filter = [];
    const promises = {
      city: call(getCity),
      adc: call(getAdc),
      adcPincode: call(getAllAdcListApi),
    };
    const responsedata = yield all(promises);
    const filterAdc = responsedata?.adc?.data;
    filterAdc.map(adc => {
      filterType = { text: adc.adcCode, value: adc.adcCode };
      filter.push(filterType);
    });
    const masterData = { ...responsedata, filter };
    const respData = { masterData };
    yield put({ type: SET_INIT_ADC_DATA, data: respData });
  } catch (e) {
    console.log('Err@ getInitAdcPincodeDataSaga: ', e);
    yield put({ type: SET_ADC_LOADING, data: false });
  }
}
