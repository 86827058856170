import { takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_SEO_LIST,
  UPSERT_SEO,
  GET_SEO_BY_ID,
  GET_SEO_MAPPING_BY_PRODUCT_ID,
} from './seo-setting.action';
import {
  getAllSeoSettingListSaga,
  upsertSeoSaga,
  getSeoByIdSaga,
  getSeoMappingByProductIDSaga,
} from './seo-setting.saga';

export const seoSettingSaga = [
  takeEvery(GET_ALL_SEO_LIST, getAllSeoSettingListSaga),
  takeEvery(UPSERT_SEO, upsertSeoSaga),
  takeEvery(GET_SEO_BY_ID, getSeoByIdSaga),
  takeEvery(GET_SEO_MAPPING_BY_PRODUCT_ID, getSeoMappingByProductIDSaga),
];
