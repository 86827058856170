import { SET_INITIAL_INFO, SET_LOGGEDIN_INFO } from './initial.action';
import { SET_LOGOUT_INFO } from './feature/login/login.action';

const initialState = {
  launchInfo: null,
  initialCheckDone: false,
  isLoggedIn: false,
};

const initialReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INITIAL_INFO:
      return {
        ...state,
        ...data,
      };
    case SET_LOGGEDIN_INFO:
      // const { isLoggedIn, role } = data;
      return {
        ...state,
        ...data,
      };
    case SET_LOGOUT_INFO:
      return {
        launchInfo: null,
        isLoggedIn: false,
        initialCheckDone: true,
      };
    default:
      return state;
  }
};

export default initialReducer;
