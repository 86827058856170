import { takeEvery } from 'redux-saga/effects';
import { promisifySaga } from '../promisifySaga';
import {
  GET_ALL_PRODUCTS,
  GET_INIT_PRODUCT_DETAILS,
  GET_PRODUCT_PRICE_DETAILS,
  UPSERT_PRODUCT,
  GET_INIT_GENERATE_QR_DATA,
  SEARCH_SKU,
  GET_ALL_PRESENT_AND_FUTURE_PRESALE,
} from './product.action';
import {
  getAllProductsSaga,
  getInitProductDetailSaga,
  getProductPriceDetailsSaga,
  upsertProductSaga,
  searchSkuSaga,
  getInitGenerateQRDataSaga,
  getAllPresentAndFuturePresaleSaga,
} from './product.saga';

export const productSaga = [
  takeEvery(GET_INIT_PRODUCT_DETAILS, getInitProductDetailSaga),
  takeEvery(UPSERT_PRODUCT, upsertProductSaga),
  takeEvery(GET_ALL_PRODUCTS, promisifySaga(getAllProductsSaga)),
  takeEvery(
    GET_PRODUCT_PRICE_DETAILS,
    promisifySaga(getProductPriceDetailsSaga)
  ),
  takeEvery(
    GET_ALL_PRESENT_AND_FUTURE_PRESALE,
    promisifySaga(getAllPresentAndFuturePresaleSaga)
  ),
  takeEvery(GET_INIT_GENERATE_QR_DATA, getInitGenerateQRDataSaga),
  takeEvery(SEARCH_SKU, promisifySaga(searchSkuSaga)),
];
