import {
  SET_INIT_PRODUCT_DETAILS,
  SET_ALL_PRODUCTS,
  SET_LOADING_PRODUCTS,
  SET_INIT_GENERATE_QR_DATA,
  SET_LOADING,
  SET_SKU_FOR_SKID,
} from './product.action';

const initialState = {
  category: null,
  attributes: null,
  products: null,
  loadingProducts: false,
  productDetail: null,
  cancellationStates: null,
  loading: false,
  pageNo: 1,
};

const productReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INIT_GENERATE_QR_DATA:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_SKU_FOR_SKID:
      return {
        ...state,
        skus: data,
        loading: false,
      };
    case SET_INIT_PRODUCT_DETAILS:
      console.log('set product details: ', data);
      return {
        ...state,
        ...data,
        loadingProducts: false,
      };
    case SET_ALL_PRODUCTS:
      return {
        ...state,
        products: data,
        loadingProducts: false,
      };
    case SET_LOADING_PRODUCTS:
      return {
        ...state,
        loadingProducts: data,
      };
    default:
      return state;
  }
};

export default productReducer;
