import request from './../util/request';

export const getLaunchInfoApi = () => request.get('/crm/crmLaunchApi');

// export const getPingCheckApi = () =>
//   request
//     .get('/crm/ping')
//     .then(resp => resp)
//     .catch(e => 'ping-pong from local');

// export const getLocationFromCDNApi = data => {
//   const { req } = request;
//   return req.get('https://geolocation-db.com/json/');
// }
