import { call, put, all } from 'redux-saga/effects';
import {
  SET_INIT_GENERATE_QR_DATA,
  SET_SKU_FOR_SKID,
  SET_SKIDS,
  SET_LOADING,
  SET_PU_MASTER_DATA,
  SET_INVOICES,
  SET_INVOICE_BY_NO,
} from './pu.action';
import {
  searchSkuApi,
  postSkuForSkidsApi,
  searchInvoiceApi,
  searchInvoiceByNoApi,
  postInvoiceApi,
  linkQrProductApi,
  unlinkQrProductApi,
  linkSkidToInvoiceApi,
  unlinkSkidToInvoiceApi,
  getLinkedSkidApi,
  generateInvoiceApi,
  getInvoiceToLinkVehicleApi,
  linkVehicleApi,
  printInvoiceApi,
  updateSkidQtyApi,
  removeSkuFromInvoiceApi,
  addSkuToInvoiceApi,
  postInvoiceAdcValidationApi,
} from './pu.api';
import {
  getCategory,
  getSubCategory,
  getProductClass,
  getProduct,
  getCutType,
  getSize,
  getAdc,
} from './pu-master.api';
import { notification } from 'antd';
import history from '../../history';

export function* getInitGenerateQRDataSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const promises = [
      call(getCategory),
      call(getSubCategory),
      call(getProductClass),
      call(getProduct),
      call(getCutType),
      call(getSize),
    ];

    const [
      { data: category },
      { data: subCategory },
      { data: productClass },
      { data: product },
      { data: cutType },
      { data: size },
    ] = yield all(promises);
    const data = {
      category,
      subCategory,
      productClass,
      product,
      cutType,
      size,
    };
    yield put({ type: SET_INIT_GENERATE_QR_DATA, data });
  } catch (e) {
    console.log('Err@ getInitGenerateQRDataSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error Fetching data.' });
  }
}

export function* searchSkuSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: skus } = yield call(searchSkuApi, data);
    yield put({ type: SET_SKU_FOR_SKID, data: skus });
    return Promise.resolve(skus);
  } catch (e) {
    console.log('Err@ searchSkuSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error while searching SKUs.' });
    return Promise.reject(e);
  }
}

export function* postSkuForSkidSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: skids } = yield call(postSkuForSkidsApi, data);
    yield put({ type: SET_SKIDS, data: skids });
    return Promise.resolve(skids);
  } catch (e) {
    console.log('Err@ postSkuForSkidSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error while generating skids.' });
    return Promise.reject(e);
  }
}

export function* getInitSearchInvoiceSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const promises = [call(getAdc)];
    if (data?.invoiceNumber) {
      promises.push(call(searchInvoiceByNoApi, data));
    }
    const resp = yield all(promises);
    const [{ data: adc }] = resp;
    yield put({ type: SET_PU_MASTER_DATA, data: { adc } });
    if (data?.invoiceNumber) {
      return Promise.resolve(resp[1].data);
    }
  } catch (e) {
    console.log('Err@ getInitSearchInvoiceSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({
      message: 'Error fetching initial data to search Invoice.',
    });
  }
}

export function* searchInvoiceSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: invoices } = yield call(searchInvoiceApi, data);
    yield put({ type: SET_INVOICES, data: invoices });
  } catch (e) {
    console.log('Err @searchInvoiceSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error searching invoices.' });
  }
}

export function* searchInvoiceByNoSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: invoice } = yield call(searchInvoiceByNoApi, data);
    yield put({ type: SET_INVOICE_BY_NO, data: invoice });
  } catch (e) {
    console.log('Err @searchInvoiceSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error searching invoice.' });
  }
}

export function* postInvoiceSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const {
      data: { validationStatus, invoiceItems },
    } = yield call(postInvoiceAdcValidationApi, data);
    if (!validationStatus && data?.cb) {
      const inActiveRackStatusSku = yield invoiceItems
        .filter(obj => !obj.rackStatus)
        .map(obj => obj.sku);
      yield put({ type: SET_LOADING, data: false });
      notification.error({ message: 'Please Active The Rack Status' });
      data.cb(inActiveRackStatusSku, validationStatus);
    } else {
      const { data: invoiceNumber } = yield call(postInvoiceApi, data);
      yield put({ type: SET_LOADING, data: false });
      history.push(`/invoice/edit/${invoiceNumber}`);
    }
  } catch (e) {
    console.log('Err @postInvoiceSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error creating draft invoices.' });
  }
}

export function* linkQrProductSaga({ data }) {
  return linkQrProductApi(data);
}

export function* unlinkQrProductSaga({ data }) {
  return unlinkQrProductApi(data);
}

export function getLinkedSkidSaga({ data }) {
  return getLinkedSkidApi(data);
}
export function linkSkidToInvoiceSaga({ data }) {
  return linkSkidToInvoiceApi(data);
}
export function unlinkSkidToInvoiceSaga({ data }) {
  return unlinkSkidToInvoiceApi(data);
}

export function* generateInvoiceSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: invoiceData } = yield call(generateInvoiceApi, data);
    console.log('Invoice: ', invoiceData);
    yield put({ type: SET_LOADING, data: false });
    history.push(`/invoice/vehicle/${data.invoiceNumber}`);
  } catch (e) {
    console.log('Err @generateInvoiceSaga: ', e);
    const message = e?.data?.description || 'Error updating invoice.';
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message });
  }
}

export function* getInvoiceToLinkVehicleSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: invoice } = yield call(getInvoiceToLinkVehicleApi, data);
    yield put({ type: SET_INVOICE_BY_NO, data: invoice });
  } catch (e) {
    console.log('Err @getInvoiceToLinkVehicleSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message = e?.data?.description || 'Error searching invoice.';
    notification.error({ message });
  }
}

export function* linkVehicleSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    yield call(linkVehicleApi, data);
    yield put({ type: SET_LOADING, data: false });
    notification.success({ message: 'Vehicle Detail linked successfully.' });
    history.push('/invoices');
  } catch (e) {
    console.log('Err @linkVehicleSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.data?.description || 'Error linking vehicle with invoice.';
    notification.error({ message });
  }
}

export function printInvoiceSaga({ data }) {
  return printInvoiceApi(data);
}

export function* updateSkidQtySaga({ data }) {
  try {
    const resp = yield call(updateSkidQtyApi, data);
    yield call(searchInvoiceByNoSaga, { data });
    return Promise.resolve(resp);
  } catch (e) {
    console.log('Err @updateSkidQtySaga: ', e);
    return Promise.reject(e);
  }
}

export function* removeSkuFromInvoiceSaga({ data }) {
  try {
    const { data: removedSkidResp } = yield call(removeSkuFromInvoiceApi, data);
    yield call(searchInvoiceByNoSaga, { data });
    return Promise.resolve(removedSkidResp);
  } catch (e) {
    console.log('Err @removeSkuFromInvoice: ', e);
    return Promise.reject(e);
  }
}

export function* addSkuToInvoiceSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const {
      data: { validationStatus, invoiceItems },
    } = yield call(postInvoiceAdcValidationApi, data);
    if (!validationStatus && data?.cb) {
      const inActiveRackStatusSku = yield invoiceItems
        .filter(obj => !obj.rackStatus)
        .map(obj => obj.sku);
      yield put({ type: SET_LOADING, data: false });
      notification.error({ message: 'Please Active The Rack Status' });
      data.cb(inActiveRackStatusSku, validationStatus);
    } else {
      const promises = data.invoiceItem.map(item =>
        call(addSkuToInvoiceApi, item)
      );
      yield all(promises);
      yield put({ type: SET_LOADING, data: false });
      history.push(`/invoice/edit/${data.invoiceItem[0].invoiceNumber}`);
    }
  } catch (e) {
    console.log('Err @addSkuToInvoiceSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    return Promise.reject(e);
  }
}
