import { SET_BLOGS } from './blogs.action';

const initialState = {
  blogs: null,
  loading: false,
};

const blogsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_BLOGS:
      return {
        ...state,
        blogs: data,
        loading: false,
      };

    default:
      return state;
  }
};

export default blogsReducer;
