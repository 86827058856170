import { call, put, all } from 'redux-saga/effects';
import {
  SET_PROVISIONAL_LOADING,
  SET_INIT_PROVISIONAL_DATA,
} from './provisional-category.action';
import {
  getCategory,
  getProduct,
  getSubCategory,
} from '../master-data/master.api';

export function* getInitProvisionalSaga() {
  try {
    yield put({ type: SET_PROVISIONAL_LOADING, data: true });
    const promises = {
      category: call(getCategory),
      products: call(getProduct),
      subCategory: call(getSubCategory),
    };
    const masterData = yield all(promises);
    const respData = { masterData };

    yield put({ type: SET_INIT_PROVISIONAL_DATA, data: respData });
  } catch (e) {
    console.log('Err@ getInitProvisionalSaga: ', e);
    yield put({ type: SET_PROVISIONAL_LOADING, data: false });
  }
}
