import { SET_USER, SET_LOADING } from './login.action';

const initialState = {
  user: null,
  loading: false,
};

const loginReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default loginReducer;
