import {
  SET_ALL_SEO_LIST,
  SET_LOADING,
  SET_SEO,
  SET_SEO_MAPPINS_BY_PRODUCT_ID,
  RESET_SEO_PRODUCT,
} from './seo-setting.action';

const initialState = {
  seoList: null,
  seoById: null,
  loading: false,
  seoProduct: null,
};

const seoSettingReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ALL_SEO_LIST:
      return {
        ...state,
        seoList: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_SEO:
    case SET_SEO_MAPPINS_BY_PRODUCT_ID:
      return {
        ...state,
        seoProduct: data,
        loading: false,
      };
    case RESET_SEO_PRODUCT:
      return {
        ...state,
        seoProduct: [],
      };
    default:
      return state;
  }
};

export default seoSettingReducer;
