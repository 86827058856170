import request from '../../../util/request';

export const getSeoList = () => request.get('/crm/seo-mapping');

export const upsertSeoApi = data => request.post('/crm/seo-mapping', data);

export const getSeoByIdApi = ({ id }) => request.get(`/crm/seo-mapping/${id}`);

export const getSeoByProductIdApi = ({ productId }) =>
  request.get(`/crm/seo-mapping/product/${productId}`);

export const deleteSeoByIdApi = productSeoId =>
  request.del(`/crm/seo-mapping/${productSeoId}`);
