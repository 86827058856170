import { SET_SYSTEM_PROPERTY, SET_LOADING } from './system-property.action';

const initialState = {
  loading: false,
  systemProperty: null,
};

const systemPropertyReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_SYSTEM_PROPERTY:
      return {
        ...state,
        systemProperty: data,
        loading: false,
      };
    default:
      return state;
  }
};

export default systemPropertyReducer;
