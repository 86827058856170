const prefix = 'SEO_SETTING/';
export const GET_ALL_SEO_LIST = `${prefix}GET_ALL_SEO_LIST`;
export const SET_ALL_SEO_LIST = `${prefix}SET_ALL_SEO_LIST`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const UPSERT_SEO = `${prefix}UPSERT`;
export const GET_SEO_BY_ID = `${prefix}GET_SEO_BY_ID`;
export const SET_SEO = `${prefix}SET_SEO`;
export const GET_SEO_MAPPING_BY_PRODUCT_ID = `${prefix}GET_SEO_MAPPING_BY_PRODUCT_ID`;
export const SET_SEO_MAPPINS_BY_PRODUCT_ID = `${prefix}SET_SEO_MAPPING_BY_PRODUCT_ID`;
export const RESET_SEO_PRODUCT = `${prefix}RESET_SEO_PRODUCT`;

export const getallSeoList = () => ({
  type: GET_ALL_SEO_LIST,
});

export const upsertSeo = data => ({
  type: UPSERT_SEO,
  data,
});

export const getSeoById = data => ({
  type: GET_SEO_BY_ID,
  data,
});

export const getSeoMappingByProductID = data => ({
  type: GET_SEO_MAPPING_BY_PRODUCT_ID,
  data,
});

export const setSeoMappingByProductID = data => ({
  type: SET_SEO_MAPPINS_BY_PRODUCT_ID,
  data,
});
export const resetSeoProduct = data => ({
  type: RESET_SEO_PRODUCT,
  data,
});
