// import { call, put, all } from 'redux-saga/effects';
// import { SET_BLOGS } from './blogs.action';
import {
  getBlogDetailsApi,
  getBlogsApi,
  getCategoriesForBlogsApi,
  getSubcategoriesForBlogsApi,
  postBlogDetailsApi,
  putBlogDetailsApi,
} from './blogs.api';
// import { notification } from 'antd';
// import history from '../../history';

export function getBlogsSaga() {
  return getBlogsApi();
}

export function getBlogDetailsSaga({ data }) {
  const promises = [getCategoriesForBlogsApi(), getSubcategoriesForBlogsApi()];
  if (data.blogId) promises.push(getBlogDetailsApi(data));
  return Promise.all(promises);
}

export function* putBlogDetailsSaga({ data }) {
  let api = postBlogDetailsApi;
  if (data.blogId) {
    api = putBlogDetailsApi;
  }
  return api(data);
}
