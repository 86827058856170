import { call, put, all } from 'redux-saga/effects';
import {
  MERGE_PROMO,
  SET_ALL_PROMOS,
  SET_INIT_PROMO_DATA,
  SET_PROMO_BY_ID,
  SET_PROMO_LOADING,
  SET_PROMO_TYPE,
} from './promo.action';
import {
  getAllPromosApi,
  getPromoByIdApi,
  postPromoApi,
  putPromoApi,
  searchPromoApi,
} from './promo.api';
import {
  getCity,
  getAdc,
  getClientType,
  getCustomerType,
  getCategory,
  getProduct,
  getProductClass,
  getSubCategory,
  getPromoTypes,
} from '../master-data/master.api';

import { notification } from 'antd';
import history from '../../history';

export function* getInitPromoDetailSaga({ data }) {
  try {
    yield put({ type: SET_PROMO_LOADING, data: true });
    const promises = {
      city: call(getCity),
      adc: call(getAdc),
      clientType: call(getClientType),
      customerType: call(getCustomerType),
      category: call(getCategory),
      products: call(getProduct),
      productClass: call(getProductClass),
      subCategory: call(getSubCategory),
      promoType: call(getPromoTypes),
    };
    if (data.promoId) {
      promises.promoDetails = call(getPromoByIdApi, data);
    }

    const masterData = yield all(promises);
    const respData = { masterData };
    if (data.promoId) {
      respData.promoDetails = masterData.promoDetails;
      delete respData.masterData.promoDetails;
    }
    yield put({ type: SET_INIT_PROMO_DATA, data: respData });
  } catch (e) {
    console.log('Err@ getInitPromoDetailSaga: ', e);
    yield put({ type: SET_PROMO_LOADING, data: false });
  }
}

export function* upsertPromoSaga({ data }) {
  try {
    let api = postPromoApi;
    let message = 'Promo added successfully.';
    if (data && data.promotionId) {
      api = putPromoApi;
      message = 'Promo updated successfully.';
    }
    console.log('Post PROMO Data: ', JSON.stringify(data));
    yield call(api, data);
    history.push('/promo-list');
    notification.success({ message });
  } catch (e) {
    console.log('Err@ upsertPromoSaga: ', e);
    notification.error({
      message:
        e.error?.errorMessage ||
        'Error while adding promo data. Please try later.',
    });
  }
}

export function* getAllPromosSaga({ data = { pageNo: 0, pageSize: 100 } }) {
  try {
    yield put({ type: SET_PROMO_LOADING, data: true });
    const promises = {
      getAllPromo: call(getAllPromosApi, data),
    };
    if (data?.accessPromoType) promises.promoType = call(getPromoTypes);
    const respData = yield all(promises);

    if (data?.accessPromoType)
      yield put({ type: SET_PROMO_TYPE, data: respData?.promoType?.data });

    if (data?.pageNo) {
      yield put({ type: MERGE_PROMO, data: respData?.getAllPromo?.data });
    } else {
      yield put({ type: SET_ALL_PROMOS, data: respData?.getAllPromo?.data });
    }
  } catch (e) {
    console.log('Err@ getAllPromosSaga: ', e);
    notification.error({
      message: 'Error getting promo list. Please try later.',
    });
    yield put({ type: SET_PROMO_LOADING, data: false });
  }
}
export function* getPromoByIdSaga({ data }) {
  try {
    yield put({ type: SET_PROMO_LOADING, data: true });
    const { data: promoDetails } = yield call(getPromoByIdApi, data);
    if (!promoDetails.promotionId) throw new Error('No such promo found');
    yield put({ type: SET_PROMO_BY_ID, data: promoDetails });
  } catch (e) {
    console.log('Err@ getPromoByIdSaga: ', e);
    const errMsg =
      e?.message || 'Error getting promo details. Please try later.';
    notification.error({ message: errMsg });
    yield put({ type: SET_PROMO_LOADING, data: false });
  }
}

export function* searchPromoSaga({ data }) {
  try {
    yield put({ type: SET_PROMO_LOADING, data: true });
    const { data: promoList } = yield call(searchPromoApi, data);
    yield put({ type: SET_ALL_PROMOS, data: promoList });
  } catch (e) {
    console.log('Err @searchPromoSaga: ', e);
    yield put({ type: SET_PROMO_LOADING, data: false });
    notification.error({
      message: 'Something went wrong. Please try later.',
    });
  }
}
