import { takeEvery } from 'redux-saga/effects';
import {
  GET_ORDERS,
  GET_ORDER_BY_NO,
  PUT_ORDER,
  SEARCH_ORDERS,
  CANCEL_SHIPMENT,
  GET_FAILED_ORDERS,
  UPDATE_FAILED_ORDERS,
  GET_ORDER_PAYMENT_STATUS,
} from './order.action';
import {
  getOrdersSaga,
  getOrderByNoSaga,
  putOrderSaga,
  searchOrdersSaga,
  cancelShipmentSaga,
  getLogisticFailedOrdersSaga,
  updateFailedOrdersSaga,
  getOrderPaymentStatusSaga,
} from './order.saga';
import { promisifySaga } from '../promisifySaga';

export const orderSaga = [
  takeEvery(GET_ORDERS, getOrdersSaga),
  takeEvery(GET_ORDER_BY_NO, getOrderByNoSaga),
  takeEvery(PUT_ORDER, putOrderSaga),
  takeEvery(SEARCH_ORDERS, searchOrdersSaga),
  takeEvery(CANCEL_SHIPMENT, promisifySaga(cancelShipmentSaga)),
  takeEvery(GET_FAILED_ORDERS, getLogisticFailedOrdersSaga),
  takeEvery(UPDATE_FAILED_ORDERS, updateFailedOrdersSaga),
  takeEvery(GET_ORDER_PAYMENT_STATUS, promisifySaga(getOrderPaymentStatusSaga)),
];
