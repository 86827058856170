import request from '../../../util/request';

export const getAppUpdateDetailsApi = () =>
  request.get(`/crm/getAppForceUpdateDetail`);

export const appUpdateDetailsApi = data =>
  request.put(
    `/crm/updateAppForceUpdateDetails/${data.appForceUpdateId}`,
    data
  );

export const adcAppForceUpdateDetails = data =>
  request.post(`/crm/adcAppForceUpdateDetails`, data);
