import { createPromiseAction } from '@adobe/redux-saga-promise';

const prefix = 'PRODUCT/';
export const GET_INIT_PRODUCT_DETAILS = `${prefix}GET_INIT_PRODUCT_DETAILS`;
export const SET_INIT_PRODUCT_DETAILS = `${prefix}SET_INIT_PRODUCT_DETAILS`;
export const UPSERT_PRODUCT = `${prefix}UPSERT_PRODUCT`;
export const GET_ALL_PRODUCTS = createPromiseAction(
  `${prefix}GET_ALL_PRODUCTS`
);
export const SET_ALL_PRODUCTS = `${prefix}SET_ALL_PRODUCTS`;
export const SET_LOADING_PRODUCTS = `${prefix}SET_LOADING_PRODUCTS`;
export const GET_PRODUCT_PRICE_DETAILS = createPromiseAction(
  `${prefix}GET_PRODUCT_PRICE_DETAILS`
);
export const GET_INIT_GENERATE_QR_DATA = `${prefix}GET_INIT_GENERATE_QR_DATA`;
export const SET_INIT_GENERATE_QR_DATA = `${prefix}SET_INIT_GENERATE_QR_DATA`;
export const SEARCH_SKU = createPromiseAction(`${prefix}SEARCH_FOR_SKU`);
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SKU_FOR_SKID = `${prefix}SET_SKU_FOR_SKID`;
export const GET_ALL_PRESENT_AND_FUTURE_PRESALE = createPromiseAction(
  `${prefix}GET_ALL_PRESENT_AND_FUTURE_PRESALE`
);

export const getInitProductDetails = data => {
  return {
    type: GET_INIT_PRODUCT_DETAILS,
    data,
  };
};

export const upsertProduct = data => {
  return {
    type: UPSERT_PRODUCT,
    data,
  };
};

// export const getAllProducts = () => {
//   return {
//     type: GET_ALL_PRODUCTS
//   };
// };
export const getAllProducts = GET_ALL_PRODUCTS;

export const getProductPriceDetails = GET_PRODUCT_PRICE_DETAILS;

export const getInitGenerateQRData = () => ({
  type: GET_INIT_GENERATE_QR_DATA,
});
export const searchSku = SEARCH_SKU;

export const getAllPresentAndFuturePresale = GET_ALL_PRESENT_AND_FUTURE_PRESALE;
