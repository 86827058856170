import { call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  SET_ALL_SEO_LIST,
  SET_LOADING,
  SET_SEO,
  SET_SEO_MAPPINS_BY_PRODUCT_ID,
} from './seo-setting.action';
import {
  getSeoList,
  upsertSeoApi,
  getSeoByIdApi,
  getSeoByProductIdApi,
} from './seo-setting.api';
import history from '../../history';

export function* getAllSeoSettingListSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: seoResp } = yield call(getSeoList);
    seoResp.sort((a, b) => b.productSeoId - a.productSeoId);
    yield put({ type: SET_ALL_SEO_LIST, data: seoResp });
  } catch (e) {
    console.log('Err@ getAllSeoSettingListSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* upsertSeoSaga({ data }) {
  try {
    let message = 'SEO added successfully.';
    if (data?.productSeoId) message = 'SEO updated successfully.';
    yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(upsertSeoApi, data);
    history.goBack();
    notification.success({ message });
  } catch (error) {
    console.log('Err@ addSeoSaga: ', error);
  }
}

export function* getSeoByIdSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(getSeoByIdApi, data);
    yield put({ type: SET_SEO, data: resp });
  } catch (e) {
    console.log('Err@ getAllSeoSettingListSaga: ', e);
    notification.error({ message: e?.data?.description });
    yield put({ type: SET_LOADING, data: false });
  }
}
export function* getSeoMappingByProductIDSaga({ data }) {
  console.log('getSeoMappingByProductIDSaga', data);
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(getSeoByProductIdApi, data);
    yield put({ type: SET_SEO_MAPPINS_BY_PRODUCT_ID, data: resp });
  } catch (e) {
    console.log('Err@ getSeoMappingByProductIDSaga: ', e);
    notification.error({ message: e?.data?.description });
    yield put({ type: SET_LOADING, data: false });
  }
}
