import { createPromiseAction } from '@adobe/redux-saga-promise';

const prefix = 'BLOGS/';
export const GET_BLOGS = createPromiseAction(`${prefix}GET_BLOGS`);
export const SET_BLOGS = `${prefix}SET_BLOGS`;
export const GET_BLOG_DETAILS = createPromiseAction(
  `${prefix}GET_BLOG_DETAILS`
);
export const PUT_BLOG_DETAILS = createPromiseAction(
  `${prefix}PUT_BLOG_DETAILS`
);

export const getBlogs = GET_BLOGS;

export const getBlogDetails = GET_BLOG_DETAILS;

export const putBlogDetails = PUT_BLOG_DETAILS;
