import request from '../../../util/request';

export const searchSkuApi = data => request.post('/pu/searchInventory', data);

export const postSkuForSkidsApi = data =>
  request.post('/pu/generateSkid', data);

export const searchInvoiceApi = data => request.post('/pu/searchInvoice', data);

export const searchInvoiceByNoApi = ({ invoiceNumber }) =>
  request.get(`/pu/invoice/${invoiceNumber}`);

export const postInvoiceApi = data => request.post('/pu/invoice', data);

export const postInvoiceAdcValidationApi = data =>
  request.post('/pu/invoiceAdcValidation', data);

export const linkQrProductApi = data => request.put('/pu/qrProdLink', data);

export const unlinkQrProductApi = data =>
  request.put('/pu/removeQrProdLinked', data);

export const getLinkedSkidApi = data =>
  request.get(`/pu/invoiceLinkedSkidList/${data.invoiceNumber}/${data.sku}`);

export const linkSkidToInvoiceApi = data =>
  request.put('/pu/invoiceLink', data);

export const unlinkSkidToInvoiceApi = data =>
  request.put('/pu/invoiceLinkedRemove', data);

// export const removeLinkedSkidApi = data => request.get(`/pu/invoiceLinkedSkidListRemove`)

export const generateInvoiceApi = data =>
  request.post('/pu/generateInvoice', data);

export const getInvoiceToLinkVehicleApi = ({ invoiceNumber }) =>
  request.get(`/pu/getInvoiceToLinkVehicle/${invoiceNumber}`);

export const linkVehicleApi = data =>
  request.post('/pu/submitVehicleAndDriverDetails', data);

export const printInvoiceApi = data =>
  request.get(`/pu/printInvoice/${data.invoiceNumber}`);

export const updateSkidQtyApi = data =>
  request.put(
    `/pu/updateInvoiceSku/${data.invoiceNumber}/${data.puInvoiceItemId}/${data.quantity}`
  );

export const removeSkuFromInvoiceApi = ({ invoiceNumber, puInvoiceItemId }) =>
  request.put(`/pu/removeInvoiceSku/${invoiceNumber}/${puInvoiceItemId}`);

export const addSkuToInvoiceApi = ({ invoiceNumber, sku, quantity }) =>
  request.put(`/pu/addInvoiceSku/${invoiceNumber}/${sku}/${quantity}`);

export const getPuWiseSkidsApi = ({ puCode }) =>
  request.get(`/pu/getProductLinkedSkids/${puCode}`);
