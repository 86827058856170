import request from '../../../util/request';

export const getAllPromosApi = ({ pageNo = 0, pageSize = 100 }) =>
  request.get(`/crm/promotion?pageNo=${pageNo}&pageSize=${pageSize}`);

export const postPromoApi = data => request.post('/crm/promotion', data);

export const putPromoApi = data => request.put('/crm/promotion', data);

export const getPromoByIdApi = ({ promoId }) =>
  request.get(`/crm/promotion/${promoId}`);

export const searchPromoApi = data =>
  request.post('/crm/promotionSearch', data);
