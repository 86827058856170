import { takeEvery } from 'redux-saga/effects';
import {
  GET_INIT_PROMO_DATA,
  GET_ALL_PROMOS,
  GET_PROMO_BY_ID,
  UPSERT_PROMO,
  SEARCH_PROMO,
} from './promo.action';
import {
  getAllPromosSaga,
  getPromoByIdSaga,
  getInitPromoDetailSaga,
  upsertPromoSaga,
  searchPromoSaga,
} from './promo.saga';

export const promoSaga = [
  takeEvery(GET_ALL_PROMOS, getAllPromosSaga),
  takeEvery(GET_PROMO_BY_ID, getPromoByIdSaga),
  takeEvery(UPSERT_PROMO, upsertPromoSaga),
  takeEvery(GET_INIT_PROMO_DATA, getInitPromoDetailSaga),
  takeEvery(SEARCH_PROMO, searchPromoSaga),
];
