import request from '../../../util/request';

export const getAllSystemPropertyApi = () => request.get('/crm/systemProperty');

export const getSystemPropertyApiById = id =>
  request.get(`/crm/systemProperty/${id}`);

export const deleteSystemPropertyApiById = id =>
  request.del(`/crm/systemProperty/${id}`);

export const upsertSystemPropertyApi = data =>
  request.post('/crm/systemProperty', data);
