import { takeEvery } from 'redux-saga/effects';
import { GET_BLOGS, PUT_BLOG_DETAILS, GET_BLOG_DETAILS } from './blogs.action';
import {
  getBlogsSaga,
  putBlogDetailsSaga,
  getBlogDetailsSaga,
} from './blogs.saga';
import { promisifySaga } from '../promisifySaga';

export const BlogsSaga = [
  takeEvery(GET_BLOGS, promisifySaga(getBlogsSaga)),
  takeEvery(PUT_BLOG_DETAILS, promisifySaga(putBlogDetailsSaga)),
  takeEvery(GET_BLOG_DETAILS, promisifySaga(getBlogDetailsSaga)),
];
