import {
  SET_CUSTOMERS,
  SET_CUSTOMER_BY_ID,
  SET_ADDRESS_BY_ID,
  SET_LOADING_CUSTOMER,
  RESET_CUSTOMER_DETAILS,
  MERGE_CUSTOMERS,
} from './customer.action';

const initialState = {
  customers: null,
  customerDetails: null,
  customerAddresses: null,
  customerOrders: null,
  loadingCustomers: false,
  showAddressDetailModal: false,
  membershipDetails: null,
};

const customerReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: data,
        loadingCustomers: false,
      };
    case MERGE_CUSTOMERS:
      return {
        ...state,
        customers: [...state.customers, ...data],
        loadingCustomers: false,
      };
    case SET_CUSTOMER_BY_ID:
      return {
        ...state,
        ...data,
        // customerDetails: data.customerDetails,
        // customerOrders: data.customerOrders,
        loadingCustomers: false,
      };
    case SET_LOADING_CUSTOMER:
      return {
        ...state,
        loadingCustomers: data,
      };
    case SET_ADDRESS_BY_ID:
      return {
        ...state,
        customerAddresses: state.customerAddresses.map(address => {
          if (
            address.id === data.id &&
            address.customerId === data.customerId
          ) {
            return data;
          } else {
            return address;
          }
        }),
      };
    case RESET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: null,
        customerOrders: null,
        loadingCustomers: false,
      };
    }
    default:
      return state;
  }
};

export default customerReducer;
