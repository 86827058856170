import { createPromiseAction } from '@adobe/redux-saga-promise';

const prefix = 'APP_UPDATE/';

export const GET_APP_UPDATE_DETAILS = `${prefix}GET_APP_UPDATE_DETAILS`;
export const SET_APP_UPDATE_DETAILS = `${prefix}SET_APP_UPDATE_DETAILS`;
export const UPDATE_DETAILS = createPromiseAction(`${prefix}UPDATE_DETAILS`);
export const SET_LOADING = `${prefix}SET_LOADING`;

export const getAppUpdateDetails = () => ({ type: GET_APP_UPDATE_DETAILS });

export const updateDetails = UPDATE_DETAILS;
