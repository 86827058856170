import {
  SET_ALL_PROMOS,
  SET_PROMO_BY_ID,
  SET_PROMO_LOADING,
  SET_INIT_PROMO_DATA,
  RESET_PROMO,
  SET_PROMO_TYPE,
  MERGE_PROMO,
} from './promo.action';

const initialState = {
  initPromoData: null,
  promoList: [],
  loadingPromo: false,
  promoDetails: null,
  masterData: null,
  promoType: [],
};

const promoReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case MERGE_PROMO:
      return {
        ...state,
        promoList: [...state.promoList, ...data],
        loadingPromo: false,
      };
    case SET_ALL_PROMOS:
      return {
        ...state,
        promoList: data,
        loadingPromo: false,
      };
    case SET_PROMO_TYPE:
      return {
        ...state,
        promoType: data,
      };
    case SET_INIT_PROMO_DATA:
      return {
        ...state,
        ...data,
        loadingPromo: false,
      };
    case SET_PROMO_BY_ID:
      return {
        ...state,
        promoDetails: data,
        loadingPromo: false,
      };
    case SET_PROMO_LOADING:
      return {
        ...state,
        loadingPromo: data,
      };
    case RESET_PROMO:
      return initialState;
    default:
      return state;
  }
};

export default promoReducer;
