const prefix = 'LOGIN/';
export const SET_USER = `${prefix}SET_USER`;
export const GET_USER = `${prefix}GET_USER`;
export const GET_LOGOUT = `${prefix}GET_LOGOUT`;
export const SET_LOGOUT_INFO = `${prefix}SET_LOGOUT_INFO`;
export const POST_AUTHENTICATE = `${prefix}POST_AUTHENTICATE`;
export const SET_LOADING = `${prefix}SET_LOADING`;

export const postAuthenticate = data => {
  // console.log('Post Authenticate: ', data);
  return {
    type: POST_AUTHENTICATE,
    data,
  };
};

export const getLogout = () => ({ type: GET_LOGOUT });
