import { takeEvery, all } from 'redux-saga/effects';
import { initialCheckSaga } from './initial.saga';
import { GET_INITIAL_INFO } from './initial.action';
import { loginSaga } from './feature/login/index.saga';
import { productSaga } from './feature/products/index.saga';
import { customerSaga } from './feature/customers/index.saga';
import { orderSaga } from './feature/orders/index.saga';
import { customTilesSaga } from './feature/custom-tile/index.saga';
import { promoSaga } from './feature/promo/index.saga';
import { provisionalCategorySaga } from './feature/provisional-category/index.saga';
import { pageSettingsSaga } from './feature/page-settings/index.saga';
import { puSaga } from './feature/pu/index.saga';
import { BlogsSaga } from './feature/blogs/index.saga';
import { AppUpdateSaga } from './feature/app-update/index.saga';
import { hbPointSaga } from './feature/hb-point/index.saga';
import { systemPropertySaga } from './feature/system-property/index.saga';
import { seoSettingSaga } from './feature/seo-setting/index.saga';
import { adcPincodeSaga } from './feature/adc-pincode/index.saga';

export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...productSaga,
    ...customerSaga,
    ...orderSaga,
    ...customTilesSaga,
    ...promoSaga,
    ...pageSettingsSaga,
    ...puSaga,
    ...BlogsSaga,
    ...AppUpdateSaga,
    ...hbPointSaga,
    ...provisionalCategorySaga,
    ...systemPropertySaga,
    ...seoSettingSaga,
    ...adcPincodeSaga,
    takeEvery(GET_INITIAL_INFO, initialCheckSaga),
  ]);
}
