import request from '../../../util/request';

export const getRolesApi = () => request.get('/crm/auth/roles');

export const getMenusApi = () => request.get('/crm/auth/menu');

export const postMenuApi = data => request.post('/crm/auth/menu', data);

export const putMenuApi = data => request.put('/crm/auth/menu', data);

export const getMenuSectionsApi = () => request.get('/crm/auth/menuSection');

export const postMenuSectionsApi = data =>
  request.post('/crm/auth/menuSection', data);

export const putMenuSectionsApi = data =>
  request.put('/crm/auth/menuSection', data);

export const getRoleByIdApi = ({ roleId }) =>
  request.get(`/crm/auth/role/${roleId}`);

export const postRoleApi = data => request.post('/crm/auth/role', data);

export const putRoleApi = data => request.put('/crm/auth/role', data);

export const getRolesAuthoritiesApi = () =>
  request.get('/crm/auth/rolesAuthorities');

export const getRolesAuthorityByIdApi = ({ roleAuthorityId }) =>
  request.get(`/crm/auth/rolesAuthority/${roleAuthorityId}`);

export const getAuthoritiesApi = () => request.get('/crm/auth/authorities');

export const putRoleAuthoritiesApi = data =>
  request.put('/crm/auth/rolesAuthority', data);

export const getAuthorityByIdApi = ({ authorityId }) =>
  request.get(`/crm/auth/authority/${authorityId}`);

export const getAuthByUserIdApi = ({ userId }) =>
  request.get(`/crm/auth/authResponse/${userId}`);

export const postAuthorityApi = data =>
  request.post('/crm/auth/authority', data);

export const putAuthorityApi = data => request.put('/crm/auth/authority', data);

export const getAuthUsersApi = () => request.get('/crm/auth/users');

export const getAuthUserByIdApi = ({ userId }) =>
  request.get(`/crm/auth/roleMapping/${userId}`);

export const putAuthUsersApi = data =>
  request.put('/crm/auth/roleMapping', data);

export const createUserApi = data => request.post('/crm/registerHbUser', data);

export const deleteHbUserApi = hbUserId =>
  request.put(`/crm/deleteHbUser/${hbUserId}`);

export const putResetUserPasswordApi = hbUserId =>
  request.put(`/crm/resetPassword/${hbUserId}`);

export const putResetPasswordAllApi = () => request.put(`/crm/resetPassword`);

export const getUserDetailsById = hbUserId =>
  request.get(`/crm/getHbUser/${hbUserId}`);

export const updateHbUser = (hbUserId, data) =>
  request.put(`/crm/updateHbUser/${hbUserId}`, data);

export const resetPasswordApi = data =>
  request.post('/crm/crmUserResetPassword', data);
