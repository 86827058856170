import { takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_CAMPAIGN_BY_ID,
  GET_ALL_CAMPAIGN_DATA,
  GET_INIT_HBPOINT_DATA,
} from './hbpoint.action';

import {
  getAllCampaignByIdSaga,
  getAllCampaignSaga,
  getHbPointDataSaga,
} from './hbpoint.saga';

export const hbPointSaga = [
  takeEvery(GET_INIT_HBPOINT_DATA, getHbPointDataSaga),
  takeEvery(GET_ALL_CAMPAIGN_DATA, getAllCampaignSaga),
  takeEvery(GET_ALL_CAMPAIGN_BY_ID, getAllCampaignByIdSaga),
];
