import request from './../../../util/request';

export const getCity = () => request.get('/crm/master/city');
export const getAdc = () => request.get('/crm/master/adc');
export const getClientType = () => request.get('/crm/master/client');
export const getCustomerType = () => request.get('/crm/master/customer');
export const getCategory = () => request.get('/crm/master/category');
export const getProduct = () => request.get('/crm/master/product');
export const getProductClass = () => request.get('/crm/master/productClass');
export const getProductClassNew = () =>
  request.get('/crm/v2/master/productClass');
export const getSubCategory = () => request.get('/crm/master/subCategory');
export const getPromoTypes = () => request.get('/crm/master/promos');
export const getCutType = () => request.get('/crm/master/cutType');
export const getSize = () => request.get('/crm/master/size');
export const getCountry = () => request.get('/crm/master/country');
export const getState = () => request.get('/crm/master/state');
export const getPaymentMode = () => request.get('/crm/master/paymentMode');
export const getCampaignCategory = () =>
  request.get('/crm/master/hbPointsCampaignCategory');

export const getLogisticPartners = () =>
  request.get('/adc/master/logisticsPartners');

export const getDepartments = () =>
  request
    .get('/crm/master/department')
    .then(resp => resp)
    .catch(e => ({
      data: [
        { deptId: '01', deptName: 'ADC' },
        { deptId: '02', deptName: 'CRM' },
      ],
    }));
