import {
  SET_INIT_HBPOINT_DATA,
  SET_HBPOINT_LOADING,
  SET_ALL_CAMPAIGN_DATA,
  SET_ALL_CAMPAIGN_BY_ID,
} from './hbpoint.action';

const initialState = {
  loadingHbPoint: false,
  masterData: null,
  campaignData: null,
  eventData: null,
};
const hbPointReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INIT_HBPOINT_DATA:
      return {
        ...state,
        ...data,
        loadingHbPoint: false,
      };
    case SET_HBPOINT_LOADING:
      return {
        ...state,
        loadingHbPoint: data,
      };
    case SET_ALL_CAMPAIGN_DATA:
      return {
        ...state,
        campaignData: data,
        eventData: null,
        loadingHbPoint: false,
      };
    case SET_ALL_CAMPAIGN_BY_ID:
      return {
        ...state,
        eventData: data,
        loadingHbPoint: false,
      };
    default:
      return state;
  }
};

export default hbPointReducer;
