import { call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

export const promisifySaga = saga => {
  return function*(action) {
    try {
      yield call(
        implementPromiseAction,
        action,
        saga.bind(null, { data: action.payload })
      );
    } catch (e) {
      console.log('Err @promisifySaga: ', saga, action);
    }
  };
};
