import { createPromiseAction } from '@adobe/redux-saga-promise';

const prefix = 'PU/';
export const GET_INIT_GENERATE_QR_DATA = `${prefix}GET_INIT_GENERATE_QR_DATA`;
export const SET_INIT_GENERATE_QR_DATA = `${prefix}SET_INIT_GENERATE_QR_DATA`;
export const SEARCH_SKU = createPromiseAction(`${prefix}SEARCH_FOR_SKU`);
export const SET_SKU_FOR_SKID = `${prefix}SET_SKU_FOR_SKID`;
export const POST_SKU_FOR_SKIDS = createPromiseAction(
  `${prefix}POST_SKU_FOR_SKIDS`
);
export const SET_SKIDS = `${prefix}SET_SKIDS`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_PU_MASTER_DATA = `${prefix}SET_PU_MASTER_DATA`;

// export const GET_INIT_SEARCH_INVOICE_DATA = `${prefix}GET_INIT_SEARCH_INVOICE_DATA`;
export const GET_INIT_SEARCH_INVOICE_DATA = createPromiseAction(
  `${prefix}GET_INIT_SEARCH_INVOICE_DATA`
);
export const SEARCH_INVOICE = `${prefix}SEARCH_INVOICE`;
export const SET_INVOICES = `${prefix}SET_INVOICES`;
export const SEARCH_INVOICE_BY_NO = `${prefix}SEARCH_INVOICE_BY_ID`;
export const SET_INVOICE_BY_NO = `${prefix}SET_INVOICE_BY_NO`;
export const POST_INVOICE = `${prefix}POST_INVOICE`;
export const SET_INVOICE_ITEM_DETAILS = `${prefix}SET_INVOICE_ITEM_DETAILS`;

export const LINK_QR_PRODUCT = createPromiseAction(`${prefix}LINK_QR_PRODUCT`);
export const UNLINK_QR_PRODUCT = createPromiseAction(
  `${prefix}UNLINK_QR_PRODUCT`
);

export const GET_LINKED_SKID = createPromiseAction(`${prefix}GET_LINKED_SKID`);
export const LINK_SKID_TO_INVOICE = createPromiseAction(
  `${prefix}LINK_SKID_TO_INVOICE`
);
export const UNLINK_SKID_TO_INVOICE = createPromiseAction(
  `${prefix}UNLINK_SKID_TO_INVOICE`
);
export const GENERATE_INVOICE = `${prefix}GENERATE_INVOICE`;
export const GET_INVOICE_TO_LINK_VEHICLE = `${prefix}GET_INVOICE_TO_LINK_VEHICLE`;
export const LINK_VEHICLE = `${prefix}LINK_VEHICLE`;
export const PRINT_INVOICE = createPromiseAction(`${prefix}PRINT_INVOICE`);
export const UPDATE_SKID_QTY = createPromiseAction(`${prefix}UPDATE_SKID_QTY`);
export const REMOVE_SKU_FROM_INVOICE = createPromiseAction(
  `${prefix}REMOVE_SKU_FROM_INVOICE`
);
export const ADD_SKU_TO_INVOICE = `${prefix}ADD_SKU_TO_INVOICE`;

export const getInitGenerateQRData = () => ({
  type: GET_INIT_GENERATE_QR_DATA,
});

// export const searchSku = data => ({ type: SEARCH_SKU, data });
export const searchSku = SEARCH_SKU;

// export const postSkuForSkid = data => ({ type: POST_SKU_FOR_SKIDS, data });
export const postSkuForSkid = POST_SKU_FOR_SKIDS;

export const setLoading = data => ({ type: SET_LOADING, data });

// export const getInitSearchInvoiceData = data => ({
//   type: GET_INIT_SEARCH_INVOICE_DATA,
//   data,
// });

export const getInitSearchInvoiceData = GET_INIT_SEARCH_INVOICE_DATA;

export const searchInvoice = data => ({ type: SEARCH_INVOICE, data });

export const searchInvoiceByNo = data => ({ type: SEARCH_INVOICE_BY_NO, data });

export const postInvoice = data => ({ type: POST_INVOICE, data });

export const setPuInvoiceItemDetails = data => ({
  type: SET_INVOICE_ITEM_DETAILS,
  data,
});

export const linkQRProduct = LINK_QR_PRODUCT;
export const unlinkQRProduct = UNLINK_QR_PRODUCT;

export const getLinkedSkid = GET_LINKED_SKID;
export const linkSkidToInvoice = LINK_SKID_TO_INVOICE;
export const unlinkSkidToInvoice = UNLINK_SKID_TO_INVOICE;
export const generateInvoice = data => ({ type: GENERATE_INVOICE, data });
export const getInvoiceToLinkVehicle = data => ({
  type: GET_INVOICE_TO_LINK_VEHICLE,
  data,
});
export const linkVehicle = data => ({ type: LINK_VEHICLE, data });
export const printInvoice = PRINT_INVOICE;
export const updateSkidQty = UPDATE_SKID_QTY;
export const removeSkuFromInvoice = REMOVE_SKU_FROM_INVOICE;
export const addSkuToInvoice = data => ({ type: ADD_SKU_TO_INVOICE, data });
