import {
  SET_ALL_CT,
  SET_INIT_CT_DETAILS,
  SET_LOADING_CT,
} from './custom-tile.action';

const initialState = {
  customerTypes: null,
  clientTypes: null,
  actionTypes: null,
  customTileConfig: null,
  customTileList: null,
  loadingCT: false,
};

const customTileReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INIT_CT_DETAILS:
      return {
        ...state,
        ...data,
        loadingCT: false,
      };
    case SET_ALL_CT:
      return {
        ...state,
        customTileList: data,
        loadingCT: false,
      };
    case SET_LOADING_CT:
      return {
        ...state,
        loadingCT: data,
      };
    default:
      return state;
  }
};

export default customTileReducer;
