import { call, put, all } from 'redux-saga/effects';
import { SET_LOADING, SET_APP_UPDATE_DETAILS } from './app-update.action';
import { getAppUpdateDetailsApi, appUpdateDetailsApi } from './app-update.api';
import { getAuthUsersApi } from '../auth/auth.api';

export function* getAppUpdateDetailsSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const promises = [call(getAppUpdateDetailsApi), call(getAuthUsersApi)];
    const promisesResp = yield all(promises);
    const responseData = {
      cfAppUpdate: promisesResp[0]?.data,
      adcUser: promisesResp[1]?.data,
    };
    responseData.adcUser = (responseData?.adcUser || []).filter(
      user => user.department === 'ADC'
    );

    yield put({ type: SET_APP_UPDATE_DETAILS, data: responseData });
  } catch (e) {
    console.log('Err@ getAppUpdateDetailsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}

export function updateDetailsSaga({ data }) {
  return appUpdateDetailsApi(data);
}
