import { SET_INIT_ADC_DATA, SET_ADC_LOADING } from './adcpincode.action';

const initialState = {
  loadingAdc: false,
  masterData: null,
};

const adcPincodeReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_INIT_ADC_DATA:
      return {
        ...state,
        ...data,
        loadingAdc: false,
      };
    case SET_ADC_LOADING:
      return {
        ...state,
        loadingAdc: data,
      };
    default:
      return state;
  }
};

export default adcPincodeReducer;
