import { createPromiseAction } from '@adobe/redux-saga-promise';
const prefix = 'PAGE_SETTINGS/';

export const GET_PAGE_SETTINGS = createPromiseAction(
  `${prefix}GET_PAGE_SETTINGS`
);
export const UPDATE_PAGE_SETTINGS = `${prefix}UPDATE_PAGE_SETTINGS`;
export const SET_PAGE_LOADING = `${prefix}SET_PAGE_LOADING`;
export const GET_ALL_PAGES = createPromiseAction(`${prefix}GET_ALL_PAGES`);
export const GET_PAGE_BY_NAME = createPromiseAction(
  `${prefix}GET_PAGE_BY_NAME`
);
export const POST_PAGE = createPromiseAction(`${prefix}POST_PAGE`);
export const PUT_PAGE = createPromiseAction(`${prefix}PUT_PAGE`);

export const getPageSettings = GET_PAGE_SETTINGS;

export const getAllPages = GET_ALL_PAGES;

export const getPageByName = GET_PAGE_BY_NAME;

export const postPage = POST_PAGE;

export const putPage = PUT_PAGE;

export const updatePageSettings = data => ({
  type: UPDATE_PAGE_SETTINGS,
  data,
});

export const setPageLoading = () => ({ type: SET_PAGE_LOADING });
