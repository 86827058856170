import request from './../../../util/request';
import { PU_LIST } from '../../../util/ConstantsService';

export const getCity = () => request.get('/pu/master/city');
export const getAdc = () => request.get('/pu/master/adc');
export const getPu = () =>
  request
    .get('/pu/master/pu')
    .then(resp => resp)
    .catch(e => ({ action: 'success', data: PU_LIST }));
export const getClientType = () => request.get('/pu/master/client');
export const getCustomerType = () => request.get('/pu/master/customer');
export const getCategory = () => request.get('/pu/master/category');
export const getProduct = () => request.get('/pu/master/product');
export const getProductClass = () => request.get('/pu/master/productClass');
export const getSubCategory = () => request.get('/pu/master/subCategory');
export const getPromoTypes = () => request.get('/pu/master/promos');
export const getCutType = () => request.get('/pu/master/cutType');
export const getSize = () => request.get('/pu/master/size');
