import { call, put } from 'redux-saga/effects';
import { SET_LOADING, SET_LOGOUT_INFO, SET_USER } from './login.action';
import { logoutApi, postAuthenticateApi, postRegisterApi } from './login.api';
import { SET_LOGGEDIN_INFO } from '../../initial.action';
import history from '../../history';
import { notification } from 'antd';
import AuthService from '../../../util/AuthService';
const { setAuthCodes } = AuthService;

export function* postAuthenticateSaga({ data }) {
  try {
    // const registerDatq = {
    //   "email" : "parth@halalbox.in",
    //   "password" : "password",
    //   "firstName" : "Parth",
    //   "middleName" : "P",
    //   "lastName" : "M",
    //   "address" : "Mumbai",
    //   "city" : "Mumbai",
    //   "state" : "Maharastra",
    //   "country" : "India",
    //   "phoneNo" : "8866255483",
    //   "department" : "TECH"
    // };
    // const { data: registerResp } = yield call(postRegisterApi, registerDatq);
    // debugger
    yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(postAuthenticateApi, data);
    setAuthCodes(resp.authResponse?.menuList);
    yield put({ type: SET_USER, resp });
    yield put({
      type: SET_LOGGEDIN_INFO,
      data: {
        isLoggedIn: resp.loggedIn,
        role: resp.role,
        email: resp.email,
      },
    });
    if (
      resp.authResponse.menuSet.indexOf('PU') > -1 &&
      resp.authResponse.menuSet.indexOf('CRM') < 0
    ) {
      history.push('/pu-dashboard');
    } else {
      history.push('/dashboard');
    }
  } catch (e) {
    console.log('Err@ postAuthenticateSaga: ', e);
    notification.error({
      message: e?.data?.description || 'Error login. Try again later. ',
    });
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* getLogoutSaga() {
  try {
    yield call(logoutApi);
    yield put({ type: SET_LOGOUT_INFO });
    history.push('/');
  } catch (e) {
    console.log('Err@ getLogoutSaga: ', e);
  }
}
