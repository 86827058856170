import request from '../../../util/request';

export const getAllCampaignApi = () => request.get('/crm/getAllActiveCampaign');

export const getCampaignByCampaignIdAPI = data =>
  request.get(`/crm/getCampaignByCampaignId?hbPointsCampaignId=${data}`);

export const saveHBCampaignApi = data =>
  request.post('/crm/saveHBCampaign', data);

export const editHBCampaignApi = data =>
  request.put('/crm/editHBCampaign', data);
