import { takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_PAGES,
  GET_PAGE_BY_NAME,
  POST_PAGE,
  PUT_PAGE,
  GET_PAGE_SETTINGS,
  UPDATE_PAGE_SETTINGS,
} from './page-settings.action';
import {
  getHomePageSettingsSaga,
  getAllPagesSaga,
  getPageByNameSaga,
  postPageSaga,
  putPageSaga,
  updatePageSettingsSaga,
} from './page-settings.saga';
import { promisifySaga } from '../promisifySaga';

export const pageSettingsSaga = [
  takeEvery(GET_PAGE_SETTINGS, promisifySaga(getHomePageSettingsSaga)),
  takeEvery(GET_ALL_PAGES, promisifySaga(getAllPagesSaga)),
  takeEvery(GET_PAGE_BY_NAME, promisifySaga(getPageByNameSaga)),
  takeEvery(POST_PAGE, promisifySaga(postPageSaga)),
  takeEvery(PUT_PAGE, promisifySaga(putPageSaga)),
  takeEvery(UPDATE_PAGE_SETTINGS, updatePageSettingsSaga),
];
