import request from '../../../util/request';

export const getBlogDetailsApi = ({ blogId }) =>
  request.get(`/crm/blogs/${blogId}`);

export const postBlogDetailsApi = data => request.post('/crm/blogs', data);

export const putBlogDetailsApi = data => request.put('/crm/blogs', data);

export const getCategoriesForBlogsApi = () =>
  request.get('/crm/blogCategories');
export const getSubcategoriesForBlogsApi = () =>
  request.get('/crm/blogSubcategories');

export const getBlogsApi = () => request.get('/crm/blogs');
/// CRM:
// 1. blogs api to list all blogs
// 2. blogs api for blog details by blogId/blogSlug
// 3. blogs api to add new blog post
// 4. blogs api to update blog post by blogId
// 5. api to get all category/subcategory for blogs

/// CF:
// 1. blogs api for homepage
// 2. blogs api to list categorywise blog posts
// 3. blogs api to list sectionwise blog post
// 4. blogs api for blog details by blogId/blogSlug
