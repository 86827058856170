export const DATE_FORMAT = 'DD/MMM/YYYY';
export const DATETIME_FORMAT = 'DD/MMM/YYYY hh:mm A ';
export const RETURN_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';

export const PROMO_TYPE = {
  SHIPPINGFEE: 'SHIPPINGFEE',
  BXGX: 'BXGX',
  REGULAR: 'REGULAR',
  SUBSCRIPTION_DUMMY_PROMO: 'SUBSCRIPTION_DUMMY_PROMO',
  SINGLE_USER_COUPON: 'SINGLE_USER_COUPON',
  THIRD_PARTY_COUPON: 'THIRD_PARTY_COUPON',
};

export const COMBINATION_CRITERIA = ['AND', 'OR', 'CLEAR'];

export const INVOICE_STATUS = ['DRAFT', 'GENERATED', 'COMPLETED'];

export const ORDER_TYPES = {
  PENDING_ORDERS: 'pendingOrder',
  TODAYS_ORDERS: 'todayOrder',
  TOMORROWS_ORDERS: 'tomorrowOrder',
};

export const FORM_LAYOUT = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

export const CHANGE_PWD_FORM_LAYOUT = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const PU_LIST = [{ puCode: 'PU01', name: 'Mahim PU' }];

export const CURRENCY = [{ id: 'INR', name: 'Indian National Rupee (INR/₹)' }];

// export const ACTION_TYPE = [
//   { value: 'PLP', label: 'Category PLP' },
//   { value: 'PDP', label: 'PDP', url: '/product/{productId}/{productName}' },
//   { value: 'SPL_PAGE', label: 'Special Page', url: '/special/{pageName}' },
//   // { value: 'S_PLP', label: 'Search PLP', url: '/search?q={searchQuery}' },
//   { value: 'noAction', label: 'No Action' },
// ];

export const STATIC_NOTIFICATION_TYPE = ['WELCOME'];

export const INVOICE_PRINT_STYLE = `
.invoice-container{
    /*width: 90%;*/
    margin: 0 auto;
    padding: 4px;
    border: 1px solid #e1e1e1;
  }
  .invoice-header {
    border: 1px solid #e1e1e1;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .left-align { text-align: left!important; }
  .float-right { float: right; }
  .supplier {
    display: inline-block;
    vertical-align: top;
    width: 45%;
    text-align: center;
    /* background: cornsilk; */ 
    line-height: 1.3;
    padding: 10px;
  }
  .invoice {
    /* background: cornsilk; */
    line-height: 1.3;
    padding: 10px;
    vertical-align: top;
    width: 45%;
    display: inline-block;
    float: right;
  }
  .invoice span {
    display: inline-block;
  }
  .qr {
    margin: 10px auto 5px;
    height: 200px;
    width: 200px;
    border: 1px solid #e1e1e1;
    /* background: #032f62; */
  }
  .qr img {
      width: 100%;
      height: 100%;
    }
  .items {
    clear: both;
    padding-top: 15px;
  }
  .items thead tr {
    background: #e1e1e1;
    text-align: center;
    font-weight: bold;
    line-height: 1.3;
    padding: 2px 0;
  }
  .items tbody tr td {
    padding: 0 5px;
  }
  .notes { padding-top: 50px}
  .clearfix {
    clear: both;
  }
`;

export const QR_PRINT_STYLE = `
  @page {
    size: 4in 6in;
    margin: 0;
  }
  .product-qr-printer {
    page-break-before: always;
    display: inline-block;
    background-color: rgb(255, 255, 255);
    height: 6in;
    width: 4in;
    //border: 1px solid #717171;
    margin-top: 0px;
    padding: 5px 0 5px;
  }
  .qr-code {
    display: inline-block;
    vertical-align: top;
    width: 4in;
    height: 4in;
  }
  .qr-code img,
  svg {
    width: 100%;
    height: 100%;
  }
  .qr-details {
    padding: 0 10px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.4;
    display: inline-block; 
    font-family: 'Roboto', 'sans-serif';
    vertical-align: top;
    color: #000000;
    width: 4in;
  }
  .qr-details--item--use-by {
    font-size: 28px;
    line-height: 1.3;
  }
`;

export const ACTION_TYPE = [
  {
    value: 'PLP',
    label: 'Category PLP',
    urlPattern: '/{category}',
    hasActionValue: true,
  },
  {
    value: 'PDP',
    label: 'PDP',
    urlPattern: '/{category}/{productSeoName}',
    hasActionValue: true,
  },
  {
    value: 'SPL_PAGE',
    label: 'Special Page',
    urlPattern: '/{pageName}-spl',
    hasActionValue: true,
  },
  {
    value: 'LINK',
    label: 'Link (external/internal)',
    urlPattern: '/{linkAddress}',
    hasActionValue: true,
  },
  {
    value: 'NOTIFICATION',
    label: 'Notification',
    urlPattern: '/notifications',
    hasActionValue: false,
  },
  {
    value: 'OFFERS',
    label: 'Offers',
    urlPattern: '/offers',
    hasActionValue: false,
  },
  {
    value: 'ORDER_DETAILS',
    label: 'Order Details',
    urlPattern: '/my/order/{orderNo}',
    hasActionValue: false,
  },
  {
    value: 'PROFILE',
    label: 'Profile',
    urlPattern: '/my/details',
    hasActionValue: false,
  },
  {
    value: 'REFER_N_EARN',
    label: 'Refer N Earn',
    urlPattern: '/my/referrals',
    hasActionValue: false,
  },
  {
    value: 'WALLET',
    label: 'Wallet',
    urlPattern: '/my/halalbox-wallet',
    hasActionValue: false,
  },
  { value: 'CART', label: 'Cart', urlPattern: '/cart', hasActionValue: false },
  {
    value: 'BLOG_DETAIL',
    label: 'Blog Detail',
    urlPattern: '/{blogUrl}-bg',
    hasActionValue: true,
  },
  {
    value: 'BLOG',
    label: 'Blog Listing',
    urlPattern: '/blogs',
    hasActionValue: false,
  },
  {
    value: 'RAMADAN',
    label: 'Ramadan Subscription',
    urlPattern: '/ramadan-subscription',
    hasActionValue: false,
  },
  {
    value: 'LOGIN',
    label: 'Login',
    urlPattern: '/login',
    hasActionValue: false,
  },
  {
    value: 'FAQ',
    label: 'FAQs',
    urlPattern: '/help/faqs',
    hasActionValue: false,
  },
  {
    value: 'TNC',
    label: 'Terms & Conditions',
    urlPattern: '/help/tnc',
    hasActionValue: false,
  },
  {
    value: 'PRIVACY',
    label: 'Privacy Policy',
    urlPattern: '/help/privacy-policy',
    hasActionValue: false,
  },
  {
    value: 'NO_ACTION',
    label: 'No Action',
    urlPattern: null,
    hasActionValue: false,
  },
  {
    value: 'HB_SELECT',
    label: 'HB-Select Landing Page',
    urlPattern: '/halalbox-select',
    hasActionValue: false,
  },
  {
    value: 'HB_POINTS',
    label: 'HB Points',
    urlPattern: '/my/hb-point',
    hasActionValue: false,
  },
  {
    value: 'HB_MONEY',
    label: 'HB Money',
    urlPattern: '/my/hb-money',
    hasActionValue: false,
  },
];

export const REFUND_TO_ORIGINAL_MODE = 'original mode';

export const myOrdersPlatform = ['halalbox'];

export const STATUS_FILTER_ARRAY = [
  { text: 'Active', value: true },
  { text: 'Inactive', value: false },
];

export const YES_NO_FILTER_ARRAY = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const ADC_FILTER_ARRAY = [
  { text: 'MUM01', value: 'MUM01' },
  { text: 'MUM02', value: 'MUM02' },
  { text: 'MUM03', value: 'MUM03' },
  { text: 'MUM04', value: 'MUM04' },
  { text: 'MUM05', value: 'MUM05' },
  { text: 'MUM06', value: 'MUM06' },
  { text: 'NAM01', value: 'NAM01' },
  { text: 'NAM02', value: 'NAM02' },
];

export const DEVICE_FILTER_ARRAY = [
  { text: 'Mobile', value: 'mobile' },
  { text: 'Desktop', value: 'desktop' },
  { text: 'Android', value: 'android' },
  { text: 'Iphone', value: 'iphone' },
];

// export const MEMBERSHIP_DURATION_UNIT = ['DAYS', 'MONTHS', 'YEARS'];
export const MEMBERSHIP_DURATION_UNIT = ['days', 'months', 'years'];

export const SERVICE_TYPE_FILTER = [
  { text: 'COMPLETE', value: 'COMPLETE' },
  { text: 'PARTIAL', value: 'PARTIAL' },
];

export const CHART_CACHE_TIME_IN_TS = 3 * 60 * 60 * 1000; // 3Hours

export const ORDER_SIMULATION_STATUS = [
  { text: 'Is Not Packed', value: 'isNotPacked' },
  { text: 'Is Packed', value: 'isPacked' },
  { text: 'Is Invoice Picked', value: 'isInvoicePicked' },
  { text: 'Is Delivered', value: 'isDelivered' },
];
