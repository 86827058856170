import { takeEvery } from 'redux-saga/effects';
import {
  GET_INIT_GENERATE_QR_DATA,
  POST_SKU_FOR_SKIDS,
  SEARCH_SKU,
  GET_INIT_SEARCH_INVOICE_DATA,
  SEARCH_INVOICE,
  SEARCH_INVOICE_BY_NO,
  POST_INVOICE,
  LINK_QR_PRODUCT,
  UNLINK_QR_PRODUCT,
  GET_LINKED_SKID,
  LINK_SKID_TO_INVOICE,
  UNLINK_SKID_TO_INVOICE,
  GENERATE_INVOICE,
  GET_INVOICE_TO_LINK_VEHICLE,
  LINK_VEHICLE,
  PRINT_INVOICE,
  UPDATE_SKID_QTY,
  REMOVE_SKU_FROM_INVOICE,
  ADD_SKU_TO_INVOICE,
} from './pu.action';
import {
  getInitGenerateQRDataSaga,
  searchSkuSaga,
  postSkuForSkidSaga,
  getInitSearchInvoiceSaga,
  searchInvoiceSaga,
  searchInvoiceByNoSaga,
  postInvoiceSaga,
  linkQrProductSaga,
  unlinkQrProductSaga,
  getLinkedSkidSaga,
  linkSkidToInvoiceSaga,
  unlinkSkidToInvoiceSaga,
  generateInvoiceSaga,
  getInvoiceToLinkVehicleSaga,
  linkVehicleSaga,
  printInvoiceSaga,
  updateSkidQtySaga,
  removeSkuFromInvoiceSaga,
  addSkuToInvoiceSaga,
} from './pu.saga';
import { promisifySaga } from '../promisifySaga';

export const puSaga = [
  takeEvery(GET_INIT_GENERATE_QR_DATA, getInitGenerateQRDataSaga),
  takeEvery(SEARCH_SKU, promisifySaga(searchSkuSaga)),
  takeEvery(POST_SKU_FOR_SKIDS, promisifySaga(postSkuForSkidSaga)),
  takeEvery(
    GET_INIT_SEARCH_INVOICE_DATA,
    promisifySaga(getInitSearchInvoiceSaga)
  ),
  takeEvery(SEARCH_INVOICE, searchInvoiceSaga),
  takeEvery(SEARCH_INVOICE_BY_NO, searchInvoiceByNoSaga),
  takeEvery(POST_INVOICE, postInvoiceSaga),
  takeEvery(LINK_QR_PRODUCT, promisifySaga(linkQrProductSaga)),
  takeEvery(UNLINK_QR_PRODUCT, promisifySaga(unlinkQrProductSaga)),
  takeEvery(GET_LINKED_SKID, promisifySaga(getLinkedSkidSaga)),
  takeEvery(LINK_SKID_TO_INVOICE, promisifySaga(linkSkidToInvoiceSaga)),
  takeEvery(UNLINK_SKID_TO_INVOICE, promisifySaga(unlinkSkidToInvoiceSaga)),
  takeEvery(GENERATE_INVOICE, generateInvoiceSaga),
  takeEvery(GET_INVOICE_TO_LINK_VEHICLE, getInvoiceToLinkVehicleSaga),
  takeEvery(LINK_VEHICLE, linkVehicleSaga),
  takeEvery(PRINT_INVOICE, promisifySaga(printInvoiceSaga)),
  takeEvery(UPDATE_SKID_QTY, promisifySaga(updateSkidQtySaga)),
  takeEvery(REMOVE_SKU_FROM_INVOICE, promisifySaga(removeSkuFromInvoiceSaga)),
  takeEvery(ADD_SKU_TO_INVOICE, addSkuToInvoiceSaga),
];
